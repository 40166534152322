import VueRouter from 'vue-router'
import Home from '@/js/views/Home.vue'

export default new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        auth: undefined
      }
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('@/js/views/About.vue'),
      meta: {
        auth: undefined
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/js/views/Login.vue'),
      meta: {
        auth: undefined
      }
    },
    {
      path: '/password/forgot',
      name: 'forgotPassword',
      component: () => import('@/js/views/ForgotPassword.vue'),
      meta: {
        auth: false
      }
    },
    {
      path: '/password/reset/:token',
      name: 'resetPassword',
      component: () => import('@/js/views/ResetPassword.vue'),
      meta: {
        auth: false
      }
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/js/views/Register.vue'),
      meta: {
        auth: false
      }
    },
    // {
    //   path: '/gift',
    //   name: 'gift',
    //   component: () => import('@/js/views/Gift.vue'),
    //   meta: {
    //     auth: undefined
    //   }
    // },
    {
      path: '/email/verify/:token',
      name: 'verifyEmail',
      component: () => import('@/js/views/VerifyEmail.vue'),
      meta: {
        auth: false
      }
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('@/js/views/Dashboard.vue'),
      alias: [
        '/dashboard/account',
        '/dashboard/new-input-file',
        '/dashboard/input-files',
        '/dashboard/jobs/new',
        '/dashboard/jobs',
        '/dashboard/results',
        // Admin pages,
        '/dashboard/users',
        '/dashboard/statistics',
        '/dashboard/licenses'
      ],
      meta: {
        auth: true
      }
    },
    // Redirect to home page if resource is not found
    {
      path: '*',
      redirect: '/'
    }
  ]
})

// export default router
