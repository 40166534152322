<template>
    <v-footer
        app
        inset
        class="footer"
    >
        Interface made by&nbsp;<a href="http://theonlyerror.nl/" target="_blank">Gijs de Jong</a>
        <v-spacer />
        &copy; {{ new Date().getFullYear() }} PVMD. All rights reserved.
    </v-footer>
</template>

<script>

/**
     * @module components/FooterFrame
     * @desc FooterFrame component, shows the copyright information.
     */
export default {
  name: 'FooterFrame'
}
</script>

<style scoped>
    .footer {
        position: absolute !important;
    }
</style>
