<template>
    <v-app-bar
        app
        height="66"
        v-resize="onResize"
    >
        <v-toolbar-title>
            <a href="/" class="link">ASA simulator</a>
        </v-toolbar-title>

        <v-spacer/>

        <v-toolbar-items v-show="!mobile">
            <v-btn
                text
                large
                color="primary"
                to="/about"
            >
                About
            </v-btn>
            <v-btn
                text
                large
                color="primary"
                to="/register"
            >
                Register
            </v-btn>
            <v-btn
                text
                large
                color="primary"
                to="/login"
            >
                Login
            </v-btn>
        </v-toolbar-items>
        <v-menu>
            <template v-slot:activator="{ on }">
                <v-btn
                    icon
                    v-on="on"
                    v-show="mobile"
                >
                    <v-icon>mdi-menu</v-icon>
                </v-btn>
            </template>

            <v-list>
                <v-list-item
                    v-for="(item, i) in routes"
                    :key="i"
                    @click="$router.push('/' + item.toLowerCase())"
                    v-show="$route.path.toLowerCase() !== '/' + item.toLowerCase()"
                >
                    <v-list-item-title>{{ item }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-app-bar>
</template>

<script>
export default {
  name: 'HeaderFrame',
  mounted () {
    this.onResize()
  },
  data: () => ({
    mobile: false,
    routes: ['About', 'Register', 'Login']
  }),
  methods: {
    onResize () {
      this.mobile = (window.innerWidth < 520)
    }
  }
}
</script>

<style scoped>
</style>
